<template>
  <div class="Card">
    <div class="Card__Header">
      <h2 class="Card__Header__Title"></h2>
      <div class="Card__Header__Buttons">
        <CButton class="mx-1" style="background-color: #717B85; color: #dcdfe3;" @click="getChartStats(1)">
          Hoy
        </CButton>
        <CButton class="mx-1" style="background-color: #717B85; color: #dcdfe3;" @click="getChartStats(2)">
          Esta Semana
        </CButton>
        <CButton class="mx-1" style="background-color: #717B85; color: #dcdfe3;" @click="getChartStats(3)">
          Este Mes
        </CButton>
        <CButton class="mx-1" style="background-color: #717B85; color: #dcdfe3;" @click="getChartStats()">
          Todo
        </CButton>
      </div>
    </div>

    <div class="Card__Data">
        <div class="Card__Data__Item">
            <p class="Card__Data__Item__Number">{{main_chart.complaints_total}}</p>
            <p class="Card__Data__Item__Label">Total Denuncias</p>
        </div>
        <div class="Card__Data__Item">
            <p class="Card__Data__Item__Number">{{main_chart.complaints_resolved}}</p>
            <p class="Card__Data__Item__Label">Denuncias Resueltas</p>
        </div>
        <div class="Card__Data__Item">
            <p class="Card__Data__Item__Number">{{main_chart.complaints_pending}}</p>
            <p class="Card__Data__Item__Label">En trámite</p>
        </div>
        <div class="Card__Data__Item">
            <p class="Card__Data__Item__Number">{{main_chart.complaints_visualized}}</p>
            <p class="Card__Data__Item__Label">Denuncias Visualizadas</p>
        </div>
        <div class="Card__Data__Item">
            <p class="Card__Data__Item__Number">{{main_chart.complaints_canceled}}</p>
            <p class="Card__Data__Item__Label">Denuncias Canceladas</p>
        </div>
        <div class="Card__Data__Item">
            <p class="Card__Data__Item__Number">{{main_chart.complaints_no_action}}</p>
            <p class="Card__Data__Item__Label">Sin actividad</p>
        </div>
    </div>

    <div class="Card__Chart">
      <CChartLine
        :datasets="[
          {
            data: dataComplaints,
            backgroundColor:'#73A8ED',
          },
          {
            data: dataComplainResolved,
            backgroundColor:'#2B3340',
          }
        ]"
        
        :options="{
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: 1 | 2,
          legend: {
            display: false
          },
            scales: {
        xAxes: [{
            gridLines: {
                color: 'rgba(0, 0, 0, 0)',
            }
        }],
        yAxes: [{
            gridLines: {
                color: 'rgba(0, 0, 0, 0)',
            }   
        }]
    },
            scales: {
          yAxes: [
            {
              display: false,
              ticks: {
                fontColor: '#FFFFFF',
              },
            },
          ],
          xAxes: [
            {
              display: false,
              ticks: {
                fontColor: '#FFFFFF',
              },
            },
          ],
        },
        }"
      />
    </div>
  </div>
</template>

<script>
import { CChartLine } from "@coreui/vue-chartjs";

export default {
  props: ["getChartStats", "main_chart"],
  components: {
    CChartLine,
  },
  data() {
    return {};
  },
  computed: {
    dataComplaints() {
        return Object.values(this.main_chart.complaints_graph);
    },
    dataComplainResolved() {
        return Object.values(this.main_chart.complaints_resolved_graph);
    }
  },
};
</script>

<style lang="scss" scoped>
.Card {
    padding-top: 16px;
    box-shadow: 2px 0px 39px -12px rgba(0,0,0,0.52);
  &__Header {
    padding: 16px;
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__Buttons {
      display: flex;
    }
  }
  &__Data {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    &__Item {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 2px solid rgb(179, 160, 160, .5);
        padding: 2px 8px;
        &:last-of-type {
            border-right: none;
        }
        &__Number {
            font-size: 1.3rem;
            font-weight: 800;
        }
        &__Label {
            color: #8A949B;
            font-weight: 600;
        }
    }
  }  
  &__Chart {
    margin-top: 20px;
    
  }
}

@media (max-width: 950px) {
  .Card {
    &__Data {
        flex-wrap: wrap;
        &__Item {
            width: 45%;
        }
    }
  }
}
</style>
